import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import LinkedInLogo from '../../images/about-us/linkedin.png';
import { teamFullNames } from '../../constants';

const profileImg = 'profile-img';

const SamImg = () => (
  <StaticImage
    className={profileImg}
    src="../../images/about-us/people/sam.png"
    alt=""
  />
);
const BenCImg = () => (
  <StaticImage
    className={profileImg}
    src="../../images/about-us/people/ben-c.png"
    alt=""
  />
);
const jjImg = () => (
  <StaticImage
    className={profileImg}
    src="../../images/about-us/people/jj.png"
    alt=""
  />
);
const EwanImg = () => (
  <StaticImage
    className={profileImg}
    src="../../images/about-us/people/ewan.png"
    alt=""
  />
);
const HarriettImg = () => (
  <StaticImage
    className={profileImg}
    src="../../images/about-us/people/harriett.png"
    alt=""
  />
);

const teamMembers = [
  {
    name: teamFullNames.sam,
    ImgElement: SamImg,
    role: 'Founder & Managing Director',
    url: 'https://www.linkedin.com/in/sam-clothier/',
    description: [
      'Meet Sam, the founder of Exabyte Games who made the exciting transition from neuroscientist to game developer. He leads the company while playing a critical role in our technical and creative teams.',
      'Self-taught in Swift, Python, and now C#, he designed, built and published the popular mobile title Final Outpost in 2020 and currently works with our cross-platform Unity development team.',
      'Fluent in German, Sam indulges in Formula 1, listens to Nothing But Thieves, and explores all sorts of PC games in search of innovation.',
    ],
  },
  {
    name: teamFullNames.benC,
    ImgElement: BenCImg,
    role: 'Director of Technology',
    url: 'https://www.linkedin.com/in/ben-clothier/',
    description: [
      'Ben grew up playing games as much as he could, often competing with Sam for access to the family computer. Through this he developed a passion for programming and game design that led him to obtain a Computer Science degree at the University of Southampton.',
      'Proficient in Unity, Ben leads the development team on all projects as our Technical Director.',
      'Over the years he has enjoyed exploring a vast array of game genres and experiences as a player. Though, as a developer, the domains of simulation, management, and emergent narrative capture his particular interest. Outside of this, Ben finds joy in both playing the piano and climbing.',
    ],
  },
  {
    name: teamFullNames.ewan,
    ImgElement: EwanImg,
    role: 'Director of Marketing',
    url: 'https://www.linkedin.com/in/ewan-hart/',
    description: [
      'With a background in Digital Marketing and ongoing studies in Graphic Design at Nottingham Trent University, Ewan\'s primary focus lies in creating amazing content and managing our website and social media accounts.',
      'Ewan, having been part of the Final Outpost team since 2020, joined forces with Ben and his longtime friend Sam to establish Exabyte Games. Together, they are setting the foundation for content creation that will shape the company\'s future for years to come.',
      'In his downtime, Ewan watches Formula 1 and the NBA, supports Forest Green Rovers FC, and listens to The Weeknd. He\'s also actively seeking thrilling experiences to conquer his fear of heights.',
    ],
  },
  {
    name: teamFullNames.harriett,
    ImgElement: HarriettImg,
    url: 'https://www.linkedin.com/in/harriett-lewis-9258b71b9/',
    role: 'Graphic Artist',
    description: [
      'Having lived around the world, Harriett has been inspired by a multitude of art styles. She currently works full-time as a Graphic Designer, where she develops her skills in animation and design across the Adobe Suite and more.',
      'Working at Exabyte as a Graphic Artist, Harriett hopes to bring to life exciting new characters and worlds for players to witness.',
      'Harriett is also studying towards her Masters degree in Illustration at Falmouth university, where she is exploring and expanding her illustration practice.',
    ],
  },
  {
    name: teamFullNames.jj,
    ImgElement: jjImg,
    url: 'https://www.linkedin.com/in/jessie-snedden-5263931b9/',
    role: 'QA Tester',
    description: [
      'JJ\'s journey into the realm of technology began in their early years, immersed in playing and observing video games on the family PS2.',
      'With a degree in Computer Science from the University of Southampton, JJ is our QA tester, helping the development team find and eliminate bugs for future game updates.',
      'While their main interests lie with computing and engineering, they love playing games with friends, water sports and skiing. They are also hoping to try scuba diving this year!',
    ],
  },
];

const TeamCard = ({ person }) => {
  const { ImgElement, url, name, role, description } = person;
  return (
    <div className="team-profile-card">
      <div className="profile-img-container">
        <a
          className="profile-img-link"
          target="_blank"
          href={url}
          rel="noreferrer"
        >
          <ImgElement />
          <img className="profile-linked-in-icon" src={LinkedInLogo} alt="" />
        </a>
      </div>
      <div className="text-container">
        <p className="upper person-name very-bold">{name}</p>
        <p className="upper person-role">{role}</p>
        {description.map((line) => (
          <p key={line} className="person-description">
            {line}
          </p>
        ))}
      </div>
    </div>
  );
};

const TeamProfiles: React.FC = () => (
  <div className="d-flex flex-column">
    <div className="team-profiles-container">
      {teamMembers.map((person) => (
        <TeamCard key={person.name} person={person} />
      ))}
    </div>
  </div>
);

export default TeamProfiles;
