import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { applyFormId, minWidthLg } from '../../constants';

const AboutUsBanner: React.FC = () => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(
          relativePath: { eq: "about-us/banner/mobile-background.png" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        desktopImage: file(
          relativePath: { eq: "about-us/banner/desktop-background.png" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );

  const images: any = [
    getImage(mobileImage),
    {
      ...getImage(desktopImage),
      media: minWidthLg,
    },
  ];

  return (
    <BgImage image={images}>
      <Container fluid>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col className="about-img-text">
                  <h3 className="upper very-bold">About us</h3>
                  <p>
                    Read more about who we are at Exabyte Games and how we got
                    here.
                  </p>
                  <p>
                    Interested in joining the team?
                    <br />
                    We&apos;d love to hear from you.
                  </p>
                  <a href={`#${applyFormId}`}>
                    <Button className="px-lg-5 py-3 very-bold">Apply</Button>
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default AboutUsBanner;
