import React from 'react';
import Form from 'react-bootstrap/Form';
import FileField from './Fields/FileField';
import BotField from './Fields/BotField';
import NameField from './Fields/NameField';
import EmailField from './Fields/EmailField';
import SubmitBtn from './SubmitBtn';

const ContactFormAboutUs: React.FC = () => (
  <Form
    netlify-honeypot="bot-field"
    data-netlify="true"
    name="contact-about-us"
    method="POST"
  >
    <input type="hidden" name="form-name" value="contact-about-us" />

    <BotField />

    <NameField />

    <EmailField />

    <FileField />

    <SubmitBtn />
  </Form>
);
export default ContactFormAboutUs;
